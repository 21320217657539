import React from "react";
import { Route, Routes } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import loadable from "@loadable/component";
import { HelmetProvider } from 'react-helmet-async';

import { ConsecutiveProvider } from "./context/ConsecutiveContext";
import { ModalProvider } from "./context/ModalContext";
import NoticeProvider from "./context/NoticeContext";
import "./styles/index.min.css";

// Use loadable for code splitting
const GithubAuthCallback = loadable(
	() => import("./components/GithubAuthCallback"),
);
const Modal = loadable(() => import("./components/Modal/Modal"));
const NoticeManager = loadable(
	() => import("./components/NoticeManager/NoticeManager"),
);
const PrivacyPolicy = loadable(() => import("./components/PrivacyPolicy"));
const TermsOfService = loadable(() => import("./components/TermsOfService"));
const UserDataFetcher = loadable(() => import("./components/UserDataFetcher"));
const Confirm = loadable(() => import("./pages/Authentication/Confirm"));
const ForgotPassword = loadable(() => import("./pages/Authentication/Forgot"));
const Login = loadable(() => import("./pages/Authentication/Login"));
const Signup = loadable(() => import("./pages/Authentication/Signup"));
const UpdatePassword = loadable(
	() => import("./pages/Authentication/UpdatePassword"),
);
const Index = loadable(() => import("./pages/Homepage/Homepage"));
const Questions = loadable(() => import("./pages/Questions/Questions"));
const SubThankYou = loadable(() => import("./pages/SubThankYou/SubThankYou"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
	return (
		<HelmetProvider>
		<Elements stripe={stripePromise}>
			<ConsecutiveProvider>
				<ModalProvider>
					<NoticeProvider>
						<NoticeManager />
						<Modal />
						<Routes>
							<Route path="/" element={<Index />} />
							<Route path="/privacy" element={<PrivacyPolicy />} />
							<Route path="/terms" element={<TermsOfService />} />
							<Route
								path="/home"
								element={
									<UserDataFetcher>
										<Questions />
									</UserDataFetcher>
								}
							/>
							<Route path="/confirm/:token" element={<Confirm />} />
							<Route path="/signup" element={<Signup />} />
							<Route path="/login" element={<Login />} />
							<Route path="/forgot" element={<ForgotPassword />} />
							<Route path="/thanks" element={<SubThankYou />} />
							<Route
								path="/updatepassword/:token"
								element={<UpdatePassword />}
							/>
							<Route path="/github/callback" element={<GithubAuthCallback />} />
						</Routes>
					</NoticeProvider>
				</ModalProvider>
			</ConsecutiveProvider>
		</Elements>
		</HelmetProvider>
	);
};

export default App;
