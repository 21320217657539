import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { loadableReady } from "@loadable/component";
import App from "./App";
import { LoginProvider } from "./context/AuthContext";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Wait for any loadable components to be ready
loadableReady(() => {
	const root = document.getElementById("root");

	// Use hydrateRoot instead of createRoot for SSR
	hydrateRoot(
		root,
		<GoogleOAuthProvider clientId={clientId}>
			<LoginProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</LoginProvider>
		</GoogleOAuthProvider>,
	);
});
